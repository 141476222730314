import React, {useState, useEffect} from 'react'
import { Table, Card, Button } from 'antd'
import Helper from '../../utils/Helper'
import isEqual from 'lodash/isEqual'

const TransactionByEducation = ({ dataTable, isEducationDownloading, handleUnduh }) => {
  const [txByEducation, setTxByEducation] = useState({
    data: [],
    isFetching: true,
  })

  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Jenjang',
      dataIndex: 'jenjang',
      key: 'jenjang',
      render: text => text || '-',
    },
    {
      title: 'Nilai Transaksi',
      dataIndex: 'nilai_transaksi',
      key: 'nilai_transaksi',
      align: 'right',
      render: text => 'Rp '+Helper.formatMoney(text) || '-',
      sorter: (a, b) => a.nilai_transaksi - b.nilai_transaksi,
    },
  ]

  useEffect(() => {
    if (!isEqual(dataTable, txByEducation)) {
      setTxByEducation({
        data: dataTable?.data || [],
        isFetching: dataTable?.isFetching || false,
      })
    }
  }, [dataTable, txByEducation])

  return(
    <Card className="h-100">
      <h5 className="text-left pb-2">Total Nilai Transaksi per Jenjang</h5>
      <Table
        bordered
        rowKey={Math.random()}
        columns={columns}
        dataSource={txByEducation?.data}
        loading={txByEducation?.isFetching}
        pagination={false}
      />
      <div className="float-right mt-3">
        <Button
          disabled={txByEducation?.isFetching}
          loading={isEducationDownloading}
          type="primary"
          onClick={() => handleUnduh('education')}>Unduh</Button>
      </div>
    </Card>
  )
}

export default TransactionByEducation
