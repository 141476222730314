import Loadable from 'react-loadable'
import Loader from './components/Loader'

const allRoles =  ['admin', 'satuan-pendidikan', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'super-admin', 'pengawas-kementerian'];

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/welcome'),
      loading: Loader,
    }),
  },
  {
    path: '/change-password',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/changePassword'),
      loading: Loader,
    }),
  },

  // Dashboard
  {
    path: '/dashboard',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'satuan-pendidikan', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dashboards'),
      loading: Loader,
    }),
  },
  {
    path: '/dashboard/district/:id',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dashboards/district'),
      loading: Loader,
    }),
  },
  {
    path: '/dashboard/school/:id/city=:name',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dashboards/city'),
      loading: Loader,
    }),
  },
  {
    path: '/school/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/profileSchool'),
      loading: Loader,
    }),
  },
  {
    path: '/school/detail-transaction/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/profileSchool/Transaction'),
      loading: Loader,
    }),
  },

  // Tingkat Kepatuhan SLA
  {
    path: '/sla-compliance',
    exact: true,
    allowedRoles: ['admin', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/slaCompliance'),
      loading: Loader,
    }),
  },

  // Ringkasan Ketidaksesuaian Pemetaan Belanja
  {
    path: '/mapping-breaches',
    exact: true,
    allowedRoles: ['dinas-kabkota', 'dinas-provinsi', 'admin', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'super-admin', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/mappingBreaches'),
      loading: Loader,
    }),
  },

  // Transaksi
  {
    path: '/transaction-all',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/problematicTransaction'),
      loading: Loader,
    }),
  },
  {
    path: '/source-transaction',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/schoolList'),
      loading: Loader,
    }),
  },
  {
    path: '/source-transaction/by-city/:id/:src',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/schoolList/byKab'),
      loading: Loader,
    }),
  },
  {
    path: '/source-transaction/by-school/:id/:src',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/schoolList/bySchool'),
      loading: Loader,
    }),
  },
  {
    path: '/transaction-list',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/transaction'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-transaction',
    exact: true,
    allowedRoles: ['satuan-pendidikan'],
    component: Loadable({
      loader: () => import('./pages/goodsTransaction'),
      loading: Loader,
    }),
  },
  {
    path: '/merchants-transaction',
    exact: true,
    allowedRoles: ['satuan-pendidikan'],
    component: Loadable({
      loader: () => import('./pages/merchantsTransaction'),
      loading: Loader,
    }),
  },

  // Barang & Penyedia
  {
    path: '/pdn-umkm-list',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/pdnUmkm'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-list',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/goodsList'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-list/district/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/goodsList/district'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-list/city/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/goodsList/city'),
      loading: Loader,
    }),
  },
  {
    path: '/store-list',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/storeList'),
      loading: Loader,
    }),
  },
  {
    path: '/store-list/district/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/storeList/district'),
      loading: Loader,
    }),
  },
  {
    path: '/store-list/city/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/storeList/city'),
      loading: Loader,
    }),
  },

  // Data Agregasi
  {
    path: '/data-gap',
    exact: true,
    allowedRoles: ['admin','mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dataGap'),
      loading: Loader,
    }),
  },
  {
    path: '/general-data-quality',
    exact: true,
    allowedRoles: ['admin','mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dataQualityGeneral'),
      loading: Loader,
    }),
  },
  {
    path: '/detail-data-quality',
    exact: true,
    allowedRoles: ['admin','mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dataQualityDetail'),
      loading: Loader,
    }),
  },
  {
    path: '/dqm-integration-transaction',
    exact: true,
    allowedRoles: ['admin','mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dqmIntegration/TransactionList'),
      loading: Loader,
    }),
  },
  {
    path: '/daftar-transaksi-integrasi-bku',
    exact: true,
    allowedRoles: ['admin', 'mitra-siplah', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/dqmIntegration/TransactionList'),
      loading: Loader,
    }),
  },

  // Klien SSO
  {
    path: '/sso-client',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/ssoClient'),
      loading: Loader,
    }),
  },
  {
    path: '/sso-client/Form',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/ssoClient/Form'),
      loading: Loader,
    }),
  },
  {
    path: '/sso-client/:id/edit',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/ssoClient/Form'),
      loading: Loader,
    }),
  },

  // Manajemen Pengguna
  {
    path: '/user-list',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users'),
      loading: Loader,
    }),
  },
  {
    path: '/user-list/detail/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/Detail'),
      loading: Loader,
    }),
  },
  {
    path: '/user-list/edit/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/Edit'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission',
    exact: true,
    allowedRoles: ['admin', 'super-admin', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/users/approval'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission/detail/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/approval/Detail'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission/edit/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/approval/Edit'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission/create',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/Create'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission/log',
    exact: true,
    allowedRoles: ['admin', 'super-admin', 'pengawas-kementerian'],
    component: Loadable({
      loader: () => import('./pages/users/Log'),
      loading: Loader,
    }),
  },
]
