import React, { useState } from 'react'
import Marquee from 'react-fast-marquee'
import { Alert, Modal } from 'antd'

const ModalInfoData = ({open, onOk, onCancel}) => {
  return (
    <Modal
      title="Pengumpulan dan Pembaruan Data SIPLah"
      cancelText="Kembali"
      okText="OK"
      centered
      width={1000}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
    >
      <div className="text-center m-b-10">
        <img src="/Assets/img/info_data.png" width="600" alt="" />
      </div>
      <div style={{ textAlign: 'justify' }}>
        Data yang disajikan pada Dasbor Pengawas SIPLah merupakan data yang
        dikumpulkan dari 18 Mitra SIPLah{' '}
        <a
          style={{ color: '#26a1ab' }}
          href="https://siplah.kemdikbud.go.id/index.php/kunjungi#mitra"
          target="_blank"
          rel="noreferrer" 
        >
          (https://siplah.kemdikbud.go.id/index.php/kunjungi#mitra)
        </a>
        . Data tersebut dikirim dari sistem Mitra SIPLah ke sistem
        Kementerian Pendidikan Dasar & Menengah Republik Indonesia melalui API{' '}
        <a
          style={{ color: '#26a1ab' }}
          href="https://siplah.kemdikbud.go.id/api/siplah/openapiv2"
          target="_blank"
          rel="noreferrer" 
        >
          (https://siplah.kemdikbud.go.id/api/siplah/openapiv2)
        </a>
        . Kemudian, data diolah lebih lanjut dan diperbarui{' '}
        <b>setiap hari pada pukul 00.00 WIB</b> untuk disajikan pada Dasbor
        Pengawas SIPLah.
        <br />
        Apabila data yang disajikan pada Dasbor Pengawas SIPLah berbeda
        dengan data yang terdapat pada sistem Mitra SIPLah, maka yang
        dijadikan acuan adalah data yang terdapat pada sistem Mitra SIPLah.
        Perbedaan data tersebut dapat terjadi karena beberapa hal sebagai
        berikut namun tidak terbatas pada:
        <ol>
          <li>
            Sistem Mitra SIPLah tidak segera mengirimkan pembaruan data ke
            sistem Kementerian Pendidikan Dasar & Menengah Republik Indonesia;
          </li>
          <li>
            Terdapat perbedaan antara data yang ada pada sistem Mitra SIPLah
            dengan data yang dikirim ke Kementerian Pendidikan Dasar & Menengah Republik Indonesia melalui API;
          </li>
          <li>
            Terdapat kegagalan dalam proses pengiriman data dari sistem
            Mitra SIPLah ke sistem Kementerian Pendidikan Dasar & Menengah Republik Indonesia melalui API;
          </li>
          <li>
            Terdapat kesalahan dalam proses pengolahan data pada sistem
            Kementerian Pendidikan Dasar & Menengah Republik Indonesia; dan/atau
          </li>
          <li>
            Sistem Kementerian Pendidikan Dasar & Menengah Republik Indonesia belum mengolah dan/atau memperbarui data
            karena pembaruan data Dasbor Pengawas SIPLah dilakukan setiap
            hari pada pukul 00.00 WIB.
          </li>
        </ol>
      </div>
    </Modal>
  )
}

const InfoData = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="m-b-10">
      <Alert
        banner
        message={
          <Marquee gradient={false}>
            <div className="m-l-10">
              Pembaruan data dilakukan <b>setiap hari pada pukul 00.00 WIB</b>{' '}
              <span
                style={{ color: '#26a1ab', cursor: 'pointer' }}
                onClick={() => setIsOpen(!isOpen)}
              >
                (pelajari lebih lanjut proses pengumpulan data Mitra SIPLah ke
                  Kementerian Pendidikan Dasar & Menengah Republik Indonesia di sini)
              </span>
            </div>
          </Marquee>
        }
      />
      <ModalInfoData 
        open={isOpen}
        onOk={() => setIsOpen(!isOpen)}
        onCancel={() => setIsOpen(!isOpen)}
      />
    </div>
  )
}

export default InfoData
