import React, {useState, useRef} from 'react'
import {
  Form, Input, Button,
  Modal, message,
} from 'antd'
import { connect } from 'react-redux'
import { rejectRequest } from '../../actions/Users'

const RejectModal = ({ auth, isRejectVisible, handleOk, handleCancel, requestId, rejectStatus }) => {
  const formRef = useRef(null)
  const [onSubmit, setOnSubmit] = useState(false)

  const onFinish = (values) => {
    setOnSubmit(true)
    rejectRequest(requestId, values, auth.id_token)
    .then(() => {
      formRef.current.resetFields()
      rejectStatus(true)
      message.success('Permintaan Izin Berhasil Ditolak')
      setOnSubmit(false)
    })
    .catch(error => {
      setOnSubmit(false)
      message.error(error)
    })
  }

  const onFinishFailed = (errorInfo) => {
    
  }

  return(
    <React.Fragment>
      <Modal
        title="Tolak Permintaan Izin"
        open={isRejectVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          preserve={false}
          ref={formRef}
          name="rejectRequest"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="notes"
            rules={[{ required: true, message: 'Silakan beri alasan' }]}
          >
            <Input.TextArea rows={6} placeholder="Masukan pesan" disabled={onSubmit}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="float-right" loading={onSubmit}>
              Kirim
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default connect(
  ({auth}) => ({ auth }),
  { rejectRequest }
)(RejectModal)
