import React from "react"
import { Flex, Spin } from "antd"

const LoaderComponent = () => {
  return(
    <Flex align="center" justify="center" style={{ height: 222 }}>
      <Spin />
      <span className="sr-only">Loading...</span>
    </Flex>
  )
}

export default LoaderComponent
