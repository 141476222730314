import React, {useState, useRef} from 'react'
import {
  Form, Input, Button,
  Modal, message
} from 'antd'  
import { connect } from 'react-redux'
import { approveRequest } from '../../actions/Users'

const ApproveModal = ({ isApproveVisible, handleOk, handleCancel, approveRequest, auth, requestId, approveStatus }) => {
  const formRef = useRef(null)
  const [onSubmit, setOnSubmit] = useState(false)
  const onFinish = (values) => {
    setOnSubmit(true)
    approveRequest(requestId, values, auth.id_token)
    .then(() => {
      formRef.current.resetFields()
      approveStatus(true)
      message.success('Permintaan Izin Berhasil Disetujui')
      setOnSubmit(false)
    })
    .catch(error => {
      setOnSubmit(false)
      approveStatus(false)
      message.error(error)
      setOnSubmit(false)
    })
  }

  const onFinishFailed = (errorInfo) => {
    
  }

  return(
    <React.Fragment>
      <Modal
        title="Setujui Permintaan Izin"
        open={isApproveVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          preserve={false}
          ref={formRef}
          name="approveRequest"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="notes"
            rules={[{ required: true, message: 'Silakan masukkan alasan' }]}
          >
            <Input.TextArea rows={6} placeholder="Masukan pesan" disabled={onSubmit}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="float-right" loading={onSubmit}>
              Kirim
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default connect(
  ({auth, users}) => ({ auth, users }),
  { approveRequest }
)(ApproveModal)