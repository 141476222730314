import React, { useState, useEffect, useCallback } from "react"
import { Form, Select, Radio, ConfigProvider } from "antd"
import { connect } from "react-redux"
import { fetchProvince, fetchCity, fetchEducation } from "../../actions/AdditionalForm"
import CustomizeNotFound from "../CustomizeNotFound"
import { isArray } from "lodash"

const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const DinasKabupaten = ({
  auth,
  sendProvince,
  sendCity,
  sendEducation,
  sendSource,
  isEdit,
  editValue,
}) => {
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [educationList, setEducationList] = useState([])

  const [onLoadProvince, setOnLoadProvince] = useState(true)
  const [onLoadCity, setOnLoadCity] = useState(false)
  const [isCityReady, setIsCityReady] = useState(true)
  const [onLoadEducation, setOnLoadEducation] = useState(true)

  const [jenjangTypeValue, setJenjangTypeValue] = useState(false)

  // Fetch province, education, and funds on mount
  useEffect(() => {
    const token = auth.id_token || process.env.REACT_APP_API_KEY

    fetchProvince(token)
      .then((result) => {
        setProvinceList(result.items.sort((a, b) => a.name.localeCompare(b.name)))
        setOnLoadProvince(false)
      })
      .catch(() => {
        setProvinceList([])
        setOnLoadProvince(false)
      })

    fetchEducation(token)
      .then((result) => {
        setEducationList(result.items)
        setOnLoadEducation(false)
      })
      .catch(() => {
        setEducationList([])
        setOnLoadEducation(false)
      })
  }, [])

  // Handle edit mode
  useEffect(() => {
    if (isEdit) {
      handleChangeProvince(isArray(editValue?.province) ? editValue?.province[0] : editValue?.province, true)
      setJenjangTypeValue(editValue?.education === true)
    }
  }, [isEdit, editValue])

  // Handle province selection
  const handleChangeProvince = useCallback(
    (value, isEditMode = false) => {
      const selected = provinceList.find((item) =>
        isEditMode ? item.name === value : item.id === value
      )

      const newProvinceVal = selected ? { id: selected.id, kd_prop: selected.kd_prop } : null
      sendProvince(newProvinceVal)

      if (value) {
        setOnLoadCity(true)
        setIsCityReady(true)
        fetchCity(auth.id_token || process.env.REACT_APP_API_KEY, `limit=514&province_ids=${value}`)
          .then((result) => {
            setCityList(result.items.sort((a, b) => a.name.localeCompare(b.name)))
            setOnLoadCity(false)
            setIsCityReady(false)
          })
          .catch(() => {
            setCityList([])
            setOnLoadCity(false)
            setIsCityReady(false)
          })
      } else {
        setCityList([])
        setOnLoadCity(false)
        setIsCityReady(true)
      }
    },
    [provinceList, fetchCity, auth, sendProvince]
  )

  // Handle city selection
  const handleChangeCity = (value) => {
    const selected = cityList.find((item) => item.id === value)
    const newCityVal = selected ? { id: selected.id, kd_kabkota: selected.kd_kab_kota } : null
    sendCity(newCityVal)
  }

  // Handle education and fund selections
  const handleChangeEducation = (value) => sendEducation(value)

  return (
    <ConfigProvider renderEmpty={CustomizeNotFound}>
      <Form.Item label="Wilayah" name="province" rules={[{ required: true, message: "Wajib diisi" }]}>
        <Select allowClear style={{ width: "100%" }} placeholder="Pilih provinsi" onChange={handleChangeProvince} loading={onLoadProvince} disabled={onLoadProvince}>
          {provinceList.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Kota" name="city" rules={[{ required: true, message: "Wajib diisi" }]}>
        <Select allowClear style={{ width: "100%" }} placeholder="Pilih kabupaten/kota" onChange={handleChangeCity} disabled={isCityReady} loading={onLoadCity}>
          {cityList.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Jenjang Pendidikan" name="is_all_education_stage" initialValue={false} className={!jenjangTypeValue ? "mb-2" : ""}>
        <Radio.Group onChange={(e) => setJenjangTypeValue(e.target.value)}>
          <Radio value={false}>Pilih Sebagian</Radio>
          <Radio value={true}>Pilih Semua</Radio>
        </Radio.Group>
      </Form.Item>

      {!jenjangTypeValue && (
        <Form.Item {...tailFormItemLayout} name="education_stage" rules={[{ required: true, message: "Wajib diisi" }]}>
          <Select mode="multiple" allowClear style={{ width: "100%" }} placeholder="Pilih jenjang pendidikan" onChange={handleChangeEducation} loading={onLoadEducation} disabled={onLoadEducation}>
            {educationList.map((item, index) => (
              <Select.Option key={index} value={item.stage}>
                {item.stage}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </ConfigProvider>
  )
}

export default connect(
  ({ auth }) => ({ auth }),
  { fetchProvince, fetchCity, fetchEducation }
)(DinasKabupaten)