import React, {useState, useEffect} from 'react'
import isEqual from 'lodash/isEqual'
import {
  Row,
  Col,
  Card,
  Select,
  Button,
  Tooltip
} from 'antd'

const DashboardFilter = ({
  yearData,
  paymentData,
  transactionData,
  provinceList,
  educationList,
  fundList,
  auth,
  handleChangeProvinceFilter,
  handleChangeCityFilter,
  handleChangeYearFilter,
  handleChangeEducationFilter,
  handleChangeFundFilter,
  handleChangeTxStatusFilter,
  handleChangePaymentStatusFilter,
  handleSubmitFilter,
  yearValue,
  educationValue,
  sourceFundValue,
  transactionValue,
  paymentValue,
  cityValue,
  provinceValue,
  isHaveProvCity,
  userRole,
}) => {
  // State Hooks
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [cityDisable, setCityDisable] = useState(true)
  const [cityList, setCityList] = useState([])
  const [selectedYear, setSelectedYear] = useState(null)
  const [selectedEducation, setSelectedEducation] = useState(null)
  const [selectedSourceFund, setSelectedSourceFund] = useState(null)
  const [selectedTransactions, setSelectedTransactions] = useState(undefined)
  const [selectedPayments, setSelectedPayments] = useState(undefined)
  const [yearList, setYearList] = useState({ data: [], isFetching: true })
  const [transactionList, setTransactionList] = useState({ data: [], isFetching: true })
  const [paymentList, setPaymentList] = useState({ data: [], isFetching: true })

  // Handle update yearList, transactionList, and paymentList when props change
  useEffect(() => {
    if (!isEqual(yearData?.data, yearList?.data)) {
      setYearList({ data: yearData?.data, isFetching: yearData?.isFetching })
    }
  }, [yearData])

  useEffect(() => {
    if (!isEqual(transactionData?.data, transactionList?.data)) {
      setTransactionList({ data: transactionData?.data, isFetching: transactionData?.isFetching })
    }
  }, [transactionData])

  useEffect(() => {
    if (!isEqual(paymentData?.data, paymentList?.data)) {
      setPaymentList({ data: paymentData?.data, isFetching: paymentData?.isFetching })
    }
  }, [paymentData])

  // Handle Province Change
  const handleChangeValProvince = (val) => {
    const sortedCities = auth.allowed_cities.sort((a, b) => (a.name < b.name ? -1 : 1))
    setSelectedProvince(val)
    setSelectedCity(null)
    setCityDisable(val === undefined)
    setCityList(sortedCities.filter((d) => d.kd_prop === val))
    handleChangeProvinceFilter(val)
  }

  // Handle City Change
  const handleChangeValCity = (val) => {
    setSelectedCity(val)
    handleChangeCityFilter(val)
  }

  // Handle Year Change
  const handleChangeYear = (val) => {
    setSelectedYear(val)
    handleChangeYearFilter(val)
  }

  // Handle Education Change
  const handleChangeEducation = (val) => {
    setSelectedEducation(val)
    handleChangeEducationFilter(val)
  }

  // Handle Fund Change
  const handleChangeSumberDana = (val) => {
    setSelectedSourceFund(val)
    handleChangeFundFilter(val)
  }

  // Handle Transactions Change
  const handleChangeTransactions = (val) => {
    setSelectedTransactions(val)
    handleChangeTxStatusFilter(val)
  }

  // Handle Payments Change
  const handleChangePayments = (val) => {
    setSelectedPayments(val)
    handleChangePaymentStatusFilter(val)
  }

  // Enable City Select if City Value exists
  useEffect(() => {
    if (cityValue) {
      setCityDisable(false)
    }
  }, [cityValue])

  return(
    <Card className="mb-3">
      <Row gutter={[24,24]} className="mb-4">
        {isHaveProvCity && userRole?.role_name !== 'satuan-pendidikan' &&
          <Col xs={24} sm={6}>
            <Select
              value={provinceValue || selectedProvince}
              allowClear
              showSearch
              style={{ width: '100%' }}
              placeholder="Pilih provinsi"
              onChange={(val) => handleChangeValProvince(val)}
              loading={false}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(provinceList || []).map(item => <Select.Option key={item.kd_prop} value={item.kd_prop}>{item.name}</Select.Option>)}
            </Select>
          </Col>
        }
        <Col xs={24} sm={5}>
          <Select
            allowClear
            showSearch
            value={educationValue || selectedEducation}
            style={{ width: '100%' }}
            placeholder="Pilih jenjang pendidikan"
            onChange={(val) => handleChangeEducation(val)}
            loading={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(educationList || []).map((item,index) => <Select.Option key={index} value={item.stage}>{item.stage}</Select.Option>)}
          </Select>
        </Col>
        <Col xs={24} sm={5}>
          <Select
            mode="multiple"
            maxTagCount={1}
            value={transactionValue || selectedTransactions}
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder="Pilih Status Transaksi"
            onChange={(val) => handleChangeTransactions(val)}
            loading={transactionList?.isFetching}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            className="filter-multiple"
            dropdownStyle={{ width: '330px'}} 
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                styles={{
                  root: {
                    pointerEvents: 'none',
                  },
                }}
                title={omittedValues.map(({ label }) => label).join(', ')}
              >
                <span>+{omittedValues.length}...</span>
              </Tooltip>
            )}
          >
            {(transactionList?.data || []).map((item, index) => <Select.Option key={index} value={item.kd_status_transaksi}>{item.status_label}</Select.Option>)}
          </Select>
        </Col>
        <Col xs={24} sm={5}>
          <Select
            mode="multiple"
            maxTagCount={1}
            allowClear
            showSearch
            value={paymentValue || selectedPayments}
            style={{ width: '100%' }}
            placeholder="Pilih Status Pembayaran"
            onChange={(val) => handleChangePayments(val)}
            loading={paymentList?.isFetching}
            disable={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            className="filter-multiple"
            dropdownStyle={{ width: '265px'}}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                styles={{
                  root: {
                    pointerEvents: 'none',
                  },
                }}
                title={omittedValues.map(({ label }) => label).join(', ')}
              >
                <span>+{omittedValues.length}...</span>
              </Tooltip>
            )}
          >
            {(paymentList?.data || []).map((item, index) => <Select.Option key={index} value={item.kd_status_pembayaran}>{item.status_label}</Select.Option>)}
          </Select>
        </Col>
        <Col xs={24} sm={3}>
          <Button type="primary" className="m-r-10" onClick={handleSubmitFilter}>
            Filter/Cari
          </Button>
        </Col>
      </Row>
      <Row gutter={[24,24]}>
        {isHaveProvCity && userRole?.role_name !== 'satuan-pendidikan' &&
          <Col xs={24} sm={6}>
            <Select
              value={cityValue || selectedCity}
              allowClear
              showSearch
              style={{ width: '100%' }}
              placeholder="Pilih kabupaten/kota"
              onChange={(val) => handleChangeValCity(val)}
              disabled={cityDisable}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(cityList || []).map(item => <Select.Option key={item.kd_kab_kota} value={item.kd_kab_kota}>{item.name}</Select.Option>)}
            </Select>
          </Col>
        }
        <Col xs={24} sm={5}>
          <Select
            placeholder="Pilih Sumber Dana"
            value={sourceFundValue || selectedSourceFund}
            onChange={(val) => handleChangeSumberDana(val)}
            allowClear
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(fundList || []).map((item) => <Select.Option key={item.kd_sumber_dana} value={item.kd_sumber_dana}>{item.fund_source_name}</Select.Option>)}
          </Select>
          <br/><br/>
          <Select
            placeholder="Pilih Tahun"
            onChange={(val) => handleChangeYear(val)}
            allowClear
            value={selectedYear || yearValue}
            loading={yearList?.isFetching}
            showSearch
            style={{ width: '100%' }}
          >
            {(yearList?.data || []).map((item) => <Select.Option key={item.year} value={item.year}>{item.year}</Select.Option>)}
          </Select>
        </Col>
      </Row>
    </Card>
  )
}

export default DashboardFilter
