import React from 'react'
import { Flex, Spin } from "antd"

const Loader = () => {
  return(
    <Flex align="center" justify="center" style={{ height: 'calc(100vh - 160px)' }}>
      <Spin size="large" />
      <span className="sr-only">Loading...</span>
    </Flex>
  )
}

export default Loader
