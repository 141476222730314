import React from 'react';
import {Empty} from 'antd';

const CustomizeNotFound = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description="Data Tidak Ditemukan"
  />
)

export default CustomizeNotFound
