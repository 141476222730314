import React, {useState, useEffect} from 'react'
import { Table, Card, Button } from 'antd'
import Helper from '../../utils/Helper'
import isEqual from 'lodash/isEqual'

const TransactionByStatus = ({ isPaymentStatus, isTxStatusDownloading, handleUnduh, dataTable }) => {
  const [txByStatus, setTxByStatus] = useState({
    data: [],
    isFetching: true
  })

  useEffect(() => {
    if (!isEqual(dataTable, txByStatus)) {
      setTxByStatus({
        data: dataTable?.data || [],
        isFetching: dataTable?.isFetching || false
      })
    }
  }, [dataTable, txByStatus])

  const statusTransaksi = isPaymentStatus ? "payment_status" : "transaction_status_name"
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Status Transaksi',
      dataIndex: statusTransaksi,
      key: statusTransaksi,
      render: text => text || '-',
    },
    {
      title: 'Nilai Transaksi',
      dataIndex: 'tx_total',
      key: 'tx_total',
      align: 'right',
      render: text => 'Rp '+Helper.formatMoney(text) || '-',
      sorter: (a, b) => a.tx_total - b.tx_total,
    },
  ]
  return(
    <Card className="h-100">
      <h5 className="text-left pb-2">Total Nilai Transaksi per Status Transaksi</h5>
      <Table
        bordered
        rowKey="transaction_status_id"
        columns={columns}
        dataSource={txByStatus?.data}
        loading={txByStatus?.isFetching}
        pagination={false}
      />
      <div className="float-right mt-3">
        <Button
          disabled={txByStatus?.isFetching}
          loading={isTxStatusDownloading}
          type="primary"
          onClick={() => handleUnduh('status')}>Unduh</Button>
      </div>
    </Card>
  )
}

export default TransactionByStatus
