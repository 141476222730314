import React from "react"
import Chart from "react-apexcharts"
import { Spin } from 'antd'
import Helper from "../../utils/Helper"

const ColumnChart = ({ data, isLoading }) => {
  return(                  
    <div>
      {isLoading ?
        <div className="text-center">
          <Spin />
        </div>
      :
        <Chart
          options={{
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return Helper.convertPrice(val)
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            xaxis: {
              categories: data ? data.label : [],
              position: 'bottom',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              tooltip: {
                enabled: true,
              }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return Helper.convertSeparator(val)
                }
              }
            },
          }}
          series={
            [{
              name: 'Nilai Transaksi Berdasarkan Jenjang',
              data: data ? data.values : []
            }]
          }
          type="bar"
          height={350}
        />
      }
    </div>
  )
}

export default ColumnChart
