import React, { useState, useEffect, useCallback } from 'react'
import { Form, Input, Button, ConfigProvider } from 'antd'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchNpsn, fetchProvince, fetchCity } from '../../actions/AdditionalForm'
import { SearchOutlined } from '@ant-design/icons'
import CustomizeNotFound from '../CustomizeNotFound'

const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const SatuanPendidikan = ({ auth, fetchNpsn, fetchProvince, passValue, isEdit, editValue }) => {
  const [onSearching, setOnSearching] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const [data, setData] = useState({})

  useEffect(() => {
    if (isEdit) {
      setSearchVal(editValue?.school_permission?.npsn || '')
      setTimeout(() => {
        handleSearch()
      }, 300)
    }
  }, [isEdit, editValue])

  const handleSearch = useCallback(async () => {
    if (!searchVal) return
    
    setOnSearching(true)
    try {
      const result = await fetchNpsn(auth.id_token, searchVal)
      if (result.success && result.data) {
        setData(result.data)
        passValue(result.data)
      } else {
        setData({})
      }
    } catch (error) {
      setData({})
    } finally {
      setOnSearching(false)
    }
  }, [searchVal, fetchNpsn, auth.id_token, passValue])

  return (
    <ConfigProvider renderEmpty={CustomizeNotFound}>
      <Form.Item label="NPSN" name="npsn" rules={[{ required: true, message: "Wajib diisi" }]}>
        <div className="d-flex">
          <Input
            placeholder="Masukkan NPSN"
            onChange={(e) => setSearchVal(e.target.value)}
            disabled={onSearching}
            value={searchVal}
            onPressEnter={handleSearch}
          />
          <Button
            type="primary"
            disabled={!searchVal}
            className="ml-3"
            onClick={handleSearch}
            loading={onSearching}
            icon={<SearchOutlined />}
          />
        </div>
      </Form.Item>
      
      <Form.Item {...tailFormItemLayout} name="detail">
        <Row>
          {[
            { label: 'Nama Sekolah', value: data.name },
            { label: 'NPSN', value: data.npsn },
            { label: 'Email', value: data.email },
            { label: 'Website', value: data.website },
            { label: 'No. Telepon', value: data.nomor_telepon },
            { label: 'No. Fax', value: data.nomor_fax },
            { label: 'Alamat', value: data.alamat_jalan },
            { label: 'Rt/Rw', value: `${data.rt || '-'} / ${data.rw || '-'}` },
            { label: 'Kelurahan', value: data.desa_kelurahan },
            { label: 'Kecamatan', value: data.kecamatan },
            { label: 'Kabupaten/Kota', value: data.kabupaten },
            { label: 'Provinsi', value: data.provinsi },
            { label: 'Kode Pos', value: data.kodepos },
          ].map((item, index) => (
            <React.Fragment key={index}>
              <Col xs={4}><p>{item.label}</p></Col>
              <Col xs={8}><p><span className="mr-2">:</span>{item.value || '-'}</p></Col>
            </React.Fragment>
          ))}
        </Row>
      </Form.Item>
    </ConfigProvider>
  )
}

export default connect(
  ({ auth }) => ({ auth }),
  { fetchNpsn, fetchProvince, fetchCity }
)(SatuanPendidikan)