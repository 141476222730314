import React, { useState, useEffect, useCallback } from 'react'
import { Form, Select, Radio, ConfigProvider } from 'antd'
import { connect } from 'react-redux'
import { fetchProvince, fetchEducation, fetchFunds } from '../../actions/AdditionalForm'
import CustomizeNotFound from '../CustomizeNotFound'
import { isArray } from 'lodash'

const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const DinasProvinsi = ({ auth, fetchProvince, fetchEducation, fetchFunds, sendProvince, sendEducation, sendSource, isEdit, editValue, formIdentity }) => {
  const [provinceList, setProvinceList] = useState([])
  const [educationList, setEducationList] = useState([])
  const [fundList, setFundList] = useState([])
  const [onLoadProvince, setOnLoadProvince] = useState(true)
  const [onLoadEducation, setOnLoadEducation] = useState(true)
  const [onLoadFunds, setOnLoadFunds] = useState(true)
  const [jenjangTypeValue, setJenjangTypeValue] = useState(false)
  const [sumberDanaTypeValue, setSumberDanaTypeValue] = useState(false)

  // Fetch data on mount
  useEffect(() => {
    fetchProvince(auth.id_token)
      .then(result => {
        setProvinceList(result.items.sort((a, b) => a.name.localeCompare(b.name)))
        setOnLoadProvince(false)
      })
      .catch(() => setOnLoadProvince(false))

    fetchEducation(auth.id_token)
      .then(result => {
        setEducationList(result.items)
        setOnLoadEducation(false)
      })
      .catch(() => setOnLoadEducation(false))

    fetchFunds(auth.id_token)
      .then(result => {
        setFundList(result.items)
        setOnLoadFunds(false)
      })
      .catch(() => setOnLoadFunds(false))
  }, [auth.id_token, fetchProvince, fetchEducation, fetchFunds])

  // Handle form editing
  useEffect(() => {
    if (isEdit && !onLoadProvince && !onLoadEducation && !onLoadFunds) {
      handleEditForm()
    }
  }, [isEdit, onLoadProvince, onLoadEducation, onLoadFunds])

  const handleEditForm = useCallback(() => {
    if (editValue?.education === true) {
      setJenjangTypeValue(true)
    } else {
      handleChangeEducation(editValue?.education)
    }

    if (editValue?.fund === true) {
      setSumberDanaTypeValue(true)
    } else {
      handleChangeSource(editValue?.fund)
    }

    handleChangeProvince(isArray(editValue?.province) ? editValue?.province[0] : editValue?.province, true)
  }, [editValue])

  const handleChangeProvince = useCallback((value, isEdit = false) => {
    let newProvinceVal = isEdit
      ? provinceList.find(item => item.name === value)
      : provinceList.find(item => item.id === value)

    if (newProvinceVal) {
      sendProvince({ id: newProvinceVal.id, kd_prop: newProvinceVal.kd_prop })
    }
  }, [provinceList, sendProvince])

  const handleChangeEducation = useCallback(value => sendEducation(value), [sendEducation])

  const handleChangeSource = useCallback(value => {
    let arrFund = (value || []).map(id => {
      const fund = fundList.find(item => item.kd_sumber_dana === id)
      return fund ? { kd_sumber_dana: fund.kd_sumber_dana, fund_source_name: fund.name } : null
    }).filter(Boolean)

    sendSource(arrFund)
  }, [fundList, sendSource])

  const onChangeJenjangType = useCallback(value => {
    setJenjangTypeValue(value)
    if (value) {
      formIdentity.current.setFieldsValue({ education_stage: undefined, is_all_education_stage: true })
      sendEducation([])
    }
  }, [formIdentity, sendEducation])

  const onChangeSumberDanaType = useCallback(value => {
    setSumberDanaTypeValue(value)
    if (value) {
      formIdentity.current.setFieldsValue({ fund_sources: undefined, is_all_fund_source: true })
      sendSource([])
    }
  }, [formIdentity, sendSource])

  return (
    <ConfigProvider renderEmpty={CustomizeNotFound}>
      <Form.Item label="Wilayah" name="province" rules={[{ required: true, message: "Wajib diisi" }]}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="Pilih provinsi"
          onChange={handleChangeProvince}
          loading={onLoadProvince}
          disabled={onLoadProvince}
        >
          {provinceList.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item label="Jenjang Pendidikan" name="is_all_education_stage" initialValue={false} className={!jenjangTypeValue ? 'mb-2' : ''}>
        <Radio.Group onChange={e => onChangeJenjangType(e.target.value)} value={jenjangTypeValue}>
          <Radio value={false}>Pilih Sebagian</Radio>
          <Radio value={true}>Pilih Semua</Radio>
        </Radio.Group>
      </Form.Item>

      {!jenjangTypeValue && (
        <Form.Item {...tailFormItemLayout} name="education_stage" rules={[{ required: true, message: "Wajib diisi" }]}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Pilih jenjang pendidikan"
            onChange={handleChangeEducation}
            loading={onLoadEducation}
            disabled={onLoadEducation}
          >
            {educationList.map((item, index) => <Select.Option key={index} value={item.stage}>{item.stage}</Select.Option>)}
          </Select>
        </Form.Item>
      )}

      <Form.Item label="Sumber Dana" name="is_all_fund_source" initialValue={false} className={!sumberDanaTypeValue ? 'mb-2' : ''}>
        <Radio.Group onChange={e => onChangeSumberDanaType(e.target.value)} value={sumberDanaTypeValue}>
          <Radio value={false}>Pilih Sebagian</Radio>
          <Radio value={true}>Pilih Semua</Radio>
        </Radio.Group>
      </Form.Item>

      {!sumberDanaTypeValue && (
        <Form.Item {...tailFormItemLayout} name="fund_sources" rules={[{ required: true, message: "Wajib diisi" }]}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Pilih sumber dana"
            onChange={handleChangeSource}
            loading={onLoadFunds}
            disabled={onLoadFunds}
          >
            {fundList.map((item, index) => <Select.Option key={index} value={item.kd_sumber_dana}>{item.name}</Select.Option>)}
          </Select>
        </Form.Item>
      )}
    </ConfigProvider>
  )
}

export default connect(
  ({ auth }) => ({ auth }),
  { fetchProvince, fetchEducation, fetchFunds }
)(DinasProvinsi)