import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { last, first, map as lodashMap } from 'lodash'
import { Layout, Menu, Tooltip } from 'antd'
import {
  HomeOutlined,
  TeamOutlined,
  ShopOutlined,
  LoginOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  DeploymentUnitOutlined,
  BookOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import history from '../../utils/History'
import { updateSiteConfiguration } from '../../actions/site'
import routes from '../../routes'

const { Sider } = Layout
const { SubMenu } = Menu

const SideNav = styled(Sider)`
  .ant-menu-root.ant-menu-inline{
    margin-top: 4px
  }
  ::-webkit-scrollbar {
    width: 4px
  }
  ::-webkit-scrollbar-track {
    background: lightgrey
  }
  ::-webkit-scrollbar-thumb {
    background: darkgray
  }
  ::-webkit-scrollbar-thumb:hover {
    background: darkgrey 
  }
`

const Sidebar = ({ auth, site, dispatch }) => {
  const [role, setRole] = useState(null)
  const [newRoutes, setNewRoutes] = useState([])
  const [openKeys, setOpenKeys] = useState([]) // State untuk submenu yang terbuka

  useEffect(() => {
    if (auth?.roles) {
      setRole(first(auth.roles))
    }

    let reRoutes = []
    routes.forEach((item) => {
      const newValue =
        item?.allowedRoles?.includes(first(auth.roles)?.role_name) && item
      newValue && reRoutes.push(newValue)
    })
    setNewRoutes(lodashMap(reRoutes, "path"))
  }, [auth.roles])

  const handleKeys = (param, isOpenSub) => {
    if (isOpenSub) {
      dispatch(updateSiteConfiguration("activeSubPage", last(param)))
    } else {
      dispatch(updateSiteConfiguration("activePage", first(param.keyPath)))
      history.push(`/${first(param.keyPath)}`)
      if (param.keyPath.length > 1) {
        dispatch(updateSiteConfiguration("activeSubPage", last(param.keyPath)))
      } else {
        dispatch(updateSiteConfiguration("activeSubPage", first(param.keyPath)))
      }
    }
  }

  const handleCheckAccess = (path) => {
    return newRoutes.some((item) => path.includes(item))
  }

  const handleOpenChange = (keys) => {
    setOpenKeys(keys)
  }

  return (
    <SideNav theme="light" width={250}>
      <div className="logo">
        <img src="/Assets/img/kemdikbud_logo.png" className="img-fluid px-3" alt="" />
      </div>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[site.activePage]}
        openKeys={openKeys} // Gunakan state
        onOpenChange={handleOpenChange} // Handler submenu
        onClick={(e) => handleKeys(e)}
        items={[
          newRoutes?.includes("/dashboard") && {
            key: "dashboard",
            icon: <HomeOutlined />,
            label: "Ringkasan",
          },
          newRoutes?.includes('/sla-compliance') && {
            key: "sla-compliance",
            icon: <FieldTimeOutlined />,
            label: "Tingkat Kepatuhan SLA"
          },
          newRoutes?.includes('/mapping-breaches') && {
            key: "mapping-breaches",
            icon: <BookOutlined />,
            label: (
              <Tooltip placement="top" title="Ringkasan Ketidaksesuaian Pemetaan Belanja">
                Ringkasan Ketidaksesuaian Pemetaan Belanja
              </Tooltip>
            )
          },
          handleCheckAccess(['/transaction-all', '/transaction-list', '/source-transaction']) && {
            key: "transaction",
            icon: <DollarOutlined />,
            label: "Transaksi",
            children: [
              newRoutes?.includes('/transaction-all') && {
                key: "transaction-all",
                label: "Daftar Transaksi"
              },
              newRoutes?.includes('/source-transaction') && {
                key: "source-transaction",
                label: "Transaksi Sumber Dana"
              }
            ]
          },
          handleCheckAccess(['/pdn-umkm-list', '/goods-list', '/store-list']) && {
            key: "stores-goods",
            icon: <ShopOutlined />,
            label: "Barang & Penyedia",
            children: [
              newRoutes?.includes('/pdn-umkm-list') && {
                key: "pdn-umkm-list",
                label: "PDN dan UMKM"
              },
              newRoutes?.includes('/goods-list') && {
                key: "goods-list",
                label: "Daftar Barang"
              },
              newRoutes?.includes('/store-list') && {
                key: "store-list",
                label: "Daftar Penyedia"
              },
              newRoutes?.includes('/goods-transaction') && {
                key: "goods-transaction",
                label: "Daftar Transaksi Barang"
              },
              newRoutes?.includes('/merchants-transaction') && {
                key: "merchants-transaction",
                label: "Daftar Transaksi Mitra"
              }
            ]
          },
          handleCheckAccess(['/data-gap']) && {
            key: "data-agregate",
            icon: <DeploymentUnitOutlined />,
            label: "Data Agregasi",
            children: [
              { key: "data-gap", label: "Selisih Data" },
              { key: "general-data-quality", label: "Kualitas Data Umum" },
              { key: "detail-data-quality", label: "Kualitas Data Detail" },
              {
                key: "dqm-integration-transaction",
                label: (
                  <Tooltip placement="top" title="Data Yang Perlu Diperbaiki Sesuai dengan Standar Kualitas Data">
                    Data Yang Perlu Diperbaiki Sesuai dengan Standar Kualitas Data
                  </Tooltip>
                )
              }
            ]
          },
          newRoutes?.includes('/sso-client') && {
            key: "sso-client",
            icon: <LoginOutlined />,
            label: "Klien SSO"
          },
          handleCheckAccess(['/user-list', '/approval-permission']) && {
            key: "user-management",
            icon: <TeamOutlined />,
            label: "Manajemen Pengguna",
            children: [
              newRoutes?.includes('/user-list') && {
                key: "user-list",
                label: "Daftar Pengguna"
              },
              newRoutes?.includes('/approval-permission') && {
                key: "approval-permission",
                label: "Persetujuan Pengguna"
              },
              newRoutes?.includes('/approval-permission/create') && {
                key: "approval-permission/create",
                label: "Tambah Pengguna"
              },
              newRoutes?.includes('/approval-permission/log') && {
                key: "approval-permission/log",
                label: "Aktivitas Pengguna"
              }
            ]
          }
        ].filter(Boolean)}
      />
    </SideNav>
  )
}

export default withRouter(
  connect(({ site, auth }) => ({ site, auth }), (dispatch) => ({ dispatch }))(Sidebar)
)
