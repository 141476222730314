import { message } from 'antd'
import { isString, map as mapLodash, isEmpty, every, isEqual } from 'lodash'
import qs from 'query-string'
import history from './History';

export default class Helper {

  static convertPrice(labelValue) {
    // Twelve Zeroes for Triliunan
    return Math.abs(Number(labelValue)) >= 1.0e+12 
    
    ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+12).toFixed(2) + "T" 
    // Nine Zeroes for Miliar        
    : Math.abs(Number(labelValue)) >= 1.0e+9 

    ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "M"
    // Six Zeroes for Jutaan 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "Jt"
    // Three Zeroes for Ribuan
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    : Math.abs(Number(labelValue));
  }

  static convertIdr(val) {
    if (val) {
      if(val.toString().length < 4 || val <= 0){
        return `Rp ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
      const numberString = val.toString().includes(".") ? val.toString().split(".")[0] : val.toString();
      const sisa = numberString.length % 3;
      let rupiah = numberString.substr(0, sisa);
      const ribuan = numberString.substr(sisa).match(/\d{3}/g);
      if (ribuan) {
        const separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return val.toString().includes(".") ? `Rp ${rupiah},${val.toString().split(".")[1]}` : `Rp ${rupiah}`;
    }
    return '-';
  }

  static convertNumber(val) {
    if (val) {
      if(val.toString().length < 4 || val <= 0) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      const numberString = val.toString();
      const sisa = numberString.length % 3;
      let angka = numberString.substr(0, sisa);
      const ribuan = numberString.substr(sisa).match(/\d{3}/g);
      if (ribuan) {
        const separator = sisa ? '.' : '';
        angka += separator + ribuan.join('.');
      }
      return `${angka}`;
    }
    return 0;
  }

  static getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
    return reader;
  }

  static beforeUpload(data) {
    const isFileType = data.file.type === 'application/pdf' || 'image/jpeg' || 'image/png'
    if (!isFileType) {
      message.error('Hanya dapat mengunggah file jenis PDF, PNG atau JPEG')
    }
    const size = data.file.size / 1024 / 1024 <= 3.5

    if (!size) {
      message.error('Ukuran file harus kurang dari atau sama dengan 3.5MB')
    }
    return isFileType && size
  }

  static getChartVal(data){
    let chartVal = {}
    let labelChart
    let valueChart
    const label = []
    const values = []

    if (data) {
      data.forEach((item) => {
        if (item.jenjang) {
          labelChart = item.jenjang ? item.jenjang : '-'
        } else if (item.kd_market) {
          labelChart = item.kd_market ? item.kd_market : '-'
        } else if (item.tx_date) {
          labelChart = item.tx_date ? item.tx_date : '-'
        } else if (item.mp_name) {
          labelChart = item.mp_name ? item.mp_name : '-'
        } else if (item.payment_status) {
          labelChart = item.payment_status ? item.payment_status : '-'
        } else if (item.sumber_dana) {
          labelChart = item.sumber_dana ? item.sumber_dana : '-'
        } else if (item.fund_source) {
          labelChart = item.fund_source ? item.fund_source : '-'
        } else {
          labelChart = '-'
        }

        if (item.nilai_transaksi) {
          valueChart =  item.nilai_transaksi ? parseFloat(item.nilai_transaksi) : 0
        } else if (item.transaction_value) {
          valueChart =  item.transaction_value ? item.transaction_value : 0
        } else if (item.running_total) {
          valueChart =  item.running_total ? item.running_total : 0
        } else if (item.tx_total) {
          valueChart =  item.tx_total ? item.tx_total : 0
        } else {
          valueChart = 0
        }
        
        label.push(labelChart)
        values.push(valueChart)
      })
      chartVal = {
        label: label,
        values: values
      }
      return chartVal
    }
    return chartVal = {label: [], values: []}
  }

  static getLineChartVal(data) {
    if (!Array.isArray(data)) {
      console.error("Error: data is not an array", data);
      return [];
    }
  
    let parentArr = [];
  
    data.forEach((tx) => {
      const childLabel = [];
      const childValues = [];
  
      tx.value.forEach((item) => {
        const labelChart = item.tx_date || "-";
        const valueChart = Number(item.running_total) || 0;
  
        childLabel.push(labelChart);
        childValues.push(valueChart);
      });
  
      parentArr.push({
        name: tx.name,
        label: childLabel,
        values: childValues,
      });
    });
  
    return parentArr;
  }
  

  static spaceCheck(val){
    if (isString(val) && val.indexOf(' ') >= 0) {
      return val.split(" ")
    }
    return val
  }

  static convertSeparator(val) {
    if (val) {
      // we don't use it anymore since safari doesn't support lookbehind (YET)
      // return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")

      var parts = val.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "")
    }
    return '0'
  }

  static convertDateTime(val){
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
      "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    const date = new Date(val)
    if(val){
      let dd = date.getDate();
      let mm = date.getMonth(); //January is 0!
      let month = monthNames[mm]
      let yyyy = date.getFullYear();
      let hours = date.getHours();
      let minutes = (date.getMinutes() < 10?'0':'') + date.getMinutes();
      return dd + ' ' +  month + ' '+ yyyy + ', ' + hours + ':' + minutes + ' WIB' ;
    }
    return '-';
  }

  static capitalizeFirstLetter(val){
    let words;
    if(val){
      words = val.charAt(0).toUpperCase() + val.slice(1)
    }
    return words;
  }

  static DecimalFormatValueType(val){
    return new Intl.NumberFormat('id-ID').format(parseFloat(val?.toString()).toFixed(2));
  }

  static checkEditValue(val, type){
    let value;
    const isEditProvUserPermission = every(val, 'province_name')
    const isEditCityUserPermission = every(val, 'city_name')

    if (type === 'province'){
      if(val?.length >= 1) {
        value = isEditProvUserPermission ? mapLodash(val, "province_name") : mapLodash(val, "name")
      } else {
        value = null
      }
    }

    if (type === 'city') {
      if(val?.length >= 1){
        value = isEditCityUserPermission ? mapLodash(val, "city_name") : mapLodash(val, "name")
      } else {
        value = null
      }
    }
    
    value = isEqual(value, ['All']) === true ? 'All' : value

    return value
  }

  static getUserRole(role){
    switch (role) {
      case 'Admin':
        return 1
      case 'Satuan Pendidikan':
        return 2
      case 'Dinas Kabupaten/Kota':
        return 3
      case 'Dinas Provinsi':
        return 4
      case 'Pengawas Pusat':
        return 5
      case 'Pengawas Provinsi':
        return 6
      case 'Pengawas Kabupaten/Kota':
        return 7
      case 'Mitra-SIPLah':
        return 8
      default:
        return '-'
    }
  }

  static handleCheckData(firstValue, secondValue){
    let finalValue

    if (!isEmpty(firstValue)) {
      finalValue = firstValue
    } else if (secondValue) {
      finalValue = secondValue
    } else {
      finalValue = []
    }

    return finalValue
  }

  static formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();

      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e);
    }
  }

  static handleClickLink(e, targetUrl) {
    // prevent reload if not right click
    if (!e.ctrlKey && !e.metaKey && e.button === 0) {
      e.preventDefault()
      history.push(targetUrl)
    }
  }

  static handleClickBack = () => {
    const parsedParams = qs.parse(window.location.search)
    const backUrl = parsedParams.from || null

    if (!!backUrl) {
      history.push(decodeURIComponent(backUrl))
    } else {
      history.goBack()
    }
  }
}
